
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import store from "@/store";

export default defineComponent({
  name: "policy-information-invoice",
  components: { GoBackButton },
  props: {
    publicId: String,
    title: String
  },
  data() {
    return {
      invoice: {},
      role: variables.INDIVIDUAL_CLIENT_USER_ROLE
    };
  },
  setup() {
    const user = computed(() => {
      return store.getters.currentUser;
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Invoice", ["Policy", "Policy Information"]);
    });

    return { user };
  },
  created() {
    this.getInvoiceInformation();

    //Set page title
    document.title =
      "Policy Information: Invoice | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getInvoiceInformation() {
      const router = useRouter();

      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("invoice/" + this.publicId)
          .then(({ data }) => {
            //Assign data to variables
            // console.clear();
            // console.log(data);

            this.invoice = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    printPage(el) {
      variables.printPage(el);
    }
  }
});
